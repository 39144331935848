import React, { useRef, useState } from 'react';
import AppBar from '@material-ui/core/AppBar';
import Avatar from '@material-ui/core/Avatar';
import Badge from '@material-ui/core/Badge';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Toolbar from '@material-ui/core/Toolbar';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import HelpOutlineOutlinedIcon from '@material-ui/icons/HelpOutlineOutlined';
import NotificationsIcon from '@material-ui/icons/Notifications';
import {
  markNotificationsSeenAndDismiss,
  useGetGraduateNotifications,
} from '../../services/collections.service';
import { Role } from '../../common/enums';
import { useAuth } from '../../providers/Auth';
import { useGeneralContext } from '../../providers/GeneralContext';
import { useGraduateContext } from '../../providers/GraduateContext';
import useIsThisScreen from '../../utils/useIsThisScreen';
import HenryLogo from '../HenryLogo';
import Spinner from '../Spinner';
import WithTooltip from '../WithTooltip';
import Notifications from './Notifications';
import ProfileMenu from './ProfileMenu';
import useStyles from './style';

export default function Navbar() {
  const classes = useStyles();
  const { getAccessToken } = useAuth();
  const isXsScreen = useIsThisScreen('xs');

  const { areMissingProfileData, loadingGraduateData } = useGraduateContext({
    ignoreWarnings: true,
  });

  const {
    selectedRole,
    isLoading: loadingAuth,
    isAuthenticated,
    user,
  } = useGeneralContext();

  const {
    data: notifications,
    loading: loadingNotifications,
    error,
    refresh,
  } = useGetGraduateNotifications();

  const { handleRunJoyride } = useGraduateContext();

  const isLoading = loadingAuth || loadingNotifications || loadingGraduateData;

  const expandMoreIconRef = useRef();

  const notificationsIconRef = useRef();

  const [openProfileMenu, setOpenProfileMenu] = useState<boolean>(false);

  const [openNotifications, setOpenNotifications] = useState<boolean>(false);

  const [toDeleteNotificationIds, setToDeleteNotificationIds] = useState<
    number[]
  >([]);

  const notDeletedNotifications = notifications?.filter(
    ({ id }) => !toDeleteNotificationIds.includes(id)
  );

  const notSeenNotifications = notDeletedNotifications?.filter(
    ({ seen }) => !seen
  );

  const allNotificationsSeen = notSeenNotifications?.length === 0;

  const toggleProfileMenu = () => setOpenProfileMenu(!openProfileMenu);

  const toggleNotifications = () => {
    if (
      openNotifications &&
      (notSeenNotifications?.length || toDeleteNotificationIds.length)
    ) {
      const seenAndDismissNotifications = {
        seen: notSeenNotifications.map(({ id }) => id),
        delete: toDeleteNotificationIds,
      };
      markNotificationsSeenAndDismiss(
        getAccessToken,
        seenAndDismissNotifications
      ).then(() => refresh());
    }
    setOpenNotifications(!openNotifications);
  };

  const handleDeleteNotification = (notificationId: number) => {
    setToDeleteNotificationIds([...toDeleteNotificationIds, notificationId]);
  };

  return (
    <>
      {selectedRole === 'school-lead' && (
        <div
          style={{
            backgroundColor: 'rgb(255, 255, 1)',
            color: 'rgba(0, 0, 0, 0.87)',
            height: 40,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            fontSize: '16px',
          }}
        >
          Consulta el estado de tu AIC haciendo{' '}
          <a
            href="mailto:administracion@soyhenry.com?subject=Resumen de cuenta AIC&body=¡Hola! me gustaría conocer el estado actual de mi AIC. ¿Podrían enviarme información? Gracias."
            style={{
              fontWeight: 'bold',
              color: 'rgba(0, 0, 0, 0.87)',
              marginLeft: 4,
              textDecoration: 'none',
              cursor: 'pointer',
            }}
          >
            clic aquí
          </a>
        </div>
      )}
      <AppBar className={classes.root} position="static">
        <Container maxWidth="xl">
          <Toolbar className={classes.toolbar}>
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Grid xs={6} item>
                <HenryLogo />
              </Grid>
              <Grid xs={6} item>
                {isLoading ? (
                  <Spinner color="primary" size="40px" />
                ) : (
                  isAuthenticated && (
                    <Grid
                      container
                      direction="row"
                      justifyContent="flex-end"
                      alignItems="center"
                    >
                      {/* TODO: separate each item in other components files */}
                      {selectedRole === Role.graduate && (
                        <>
                          <Grid item>
                            <IconButton
                              className={classes.notificationsButton}
                              onClick={() =>
                                handleRunJoyride({
                                  name: 'graduateDashboard',
                                  run: true,
                                })
                              }
                            >
                              <WithTooltip
                                withTooltip
                                tooltip="App Tour"
                                style={{ height: '34px' }}
                              >
                                <HelpOutlineOutlinedIcon
                                  className={classes.icon}
                                />
                              </WithTooltip>
                            </IconButton>
                          </Grid>
                          <Grid item>
                            <IconButton
                              id="expandNotifications"
                              ref={notificationsIconRef}
                              edge="start"
                              aria-label="menu"
                              onClick={toggleNotifications}
                              disabled={isLoading || !isAuthenticated}
                              className={classes.notificationsButton}
                            >
                              <Badge
                                badgeContent={notSeenNotifications?.length}
                                invisible={allNotificationsSeen || !!error}
                                color="secondary"
                              >
                                <NotificationsIcon className={classes.icon} />
                              </Badge>
                            </IconButton>
                            <Notifications
                              open={openNotifications}
                              anchorEl={notificationsIconRef.current}
                              onClose={toggleNotifications}
                              error={!!error}
                              notifications={notDeletedNotifications}
                              onClickDeleteNotification={
                                handleDeleteNotification
                              }
                            />
                          </Grid>
                        </>
                      )}
                      <Grid item id="profile-avatar">
                        <Badge
                          badgeContent={
                            <WithTooltip
                              withTooltip
                              tooltip="Tienes datos sin completar en tu perfil."
                            >
                              !
                            </WithTooltip>
                          }
                          invisible={
                            !areMissingProfileData ||
                            selectedRole === Role.backoffice
                          }
                          color="error"
                          anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'right',
                          }}
                          className={classes.missingProfileDataBadge}
                        >
                          <Avatar
                            src={user.picture}
                            alt={user.given_name}
                            onClick={toggleProfileMenu}
                            className={classes.avatar}
                            ref={(isXsScreen && expandMoreIconRef) || null}
                          />
                        </Badge>
                        <IconButton
                          id="expandProfileMenu"
                          disabled={isLoading || !isAuthenticated}
                          onClick={toggleProfileMenu}
                          edge="start"
                          color="inherit"
                          aria-label="menu"
                          ref={(!isXsScreen && expandMoreIconRef) || null}
                          className={classes.expandMoreIcon}
                        >
                          <ExpandMoreIcon />
                        </IconButton>
                      </Grid>
                      <ProfileMenu
                        open={openProfileMenu}
                        onClose={toggleProfileMenu}
                        anchorEl={expandMoreIconRef.current}
                      />
                    </Grid>
                  )
                )}
              </Grid>
            </Grid>
          </Toolbar>
        </Container>
      </AppBar>
    </>
  );
}
